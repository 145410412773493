import { Box } from "@chakra-ui/react";
import { LogoutIcon } from "@doorstead/components";
import { CalendarIcon } from "@doorstead/components/icons/CalendarIcon";
import { ClockIcon } from "@doorstead/components/icons/ClockIcon";
import { MoneyHouseIcon } from "@doorstead/components/icons/MoneyHouseIcon";
import { WalletIcon } from "@doorstead/components/icons/WalletIcon";
import type { Props as MainLayoutProps } from "@doorstead/components/layouts/MainLayout";
import { TenantRequestFormLink } from "../components/TenantRequestFormLink/TenantRequestFormLink";
import { useStep } from "../contexts/StepContext";
import { useAuth } from "../contexts/auth";
import { getUserName } from "../utils/getUserName";
import { useGroupAnalytics } from "./useGroupAnalytics";
import { useIsTPO } from "./useIsTPO";

export enum Name {
  MakePayment = "Payments",
  PaymentMethods = "Payment Methods",
  AutoPay = "Manage Autopay",
  TransactionHistory = "Transaction History",
  InviteCoTenant = "Invite Co-tenant",
  LogOut = "Log out",
}

export const logoutLink = {
  name: Name.LogOut,
  to: "/logout",
  icon: <LogoutIcon size={31} color="gray.700" />,
};

export function useHeader(): MainLayoutProps["header"] {
  const { isDone } = useStep();
  const { user } = useAuth();
  const analytics = useGroupAnalytics();
  const isTPO = useIsTPO();
  const avatarName = getUserName({ firstName: user?.firstName, lastName: user?.lastName });
  let links = [
    {
      ...logoutLink,
      onClick: () => {
        analytics?.track("TP_MENU_LOGOUT_CLICKED");
      },
    },
  ];

  if (isDone) {
    links = [
      {
        name: Name.MakePayment,
        to: "/payment",
        icon: <MoneyHouseIcon size={20} color="gray.700" />,
        onClick: () => {
          analytics?.track("TP_MENU_PAYMENT_CLICKED");
        },
      },
      {
        name: Name.PaymentMethods,
        to: "/payment-method",
        icon: <WalletIcon size={20} color="gray.700" />,
        onClick: () => {
          analytics?.track("TP_MENU_PAYMENT_METHOD_CLICKED");
        },
      },
      {
        name: Name.AutoPay,
        to: "/autopay",
        icon: <CalendarIcon size={20} color="gray.700" />,
        onClick: () => {
          analytics?.track("TP_MENU_AUTOPAY_CLICKED");
        },
      },
      {
        name: Name.TransactionHistory,
        to: "/transaction-history",
        icon: <ClockIcon size={20} color="gray.700" />,
        onClick: () => {
          analytics?.track("TP_MENU_TXN_HISTORY_CLICKED");
        },
      },
    ].concat(links);
  }

  return {
    avatarPlaceholder: <Box mr={5}>{!isTPO && <TenantRequestFormLink />}</Box>,
    logoLink: "/",
    links,
    avatarName,
  };
}

import { Status, TextField, Props as TextFieldProps } from "@doorstead/components/fields/TextField";
import _, { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTextInputValidationOnBlurEnabled } from "src/hooks/applicants/useTextInputValidationOnBlurEnabled";

export type Props = Omit<TextFieldProps, "value" | "onChange"> & {
  defaultValue: string | null;
  transform?: (value: string) => string;
  getStatus?: (value: string) => Status;
  onSyncToServer: (value: string) => void;
  debounce?: number;
  validateOnBlur?: boolean;
  validator?: (value: string) => boolean;
};

export function ApplicantTextField({
  id,
  defaultValue,
  onSyncToServer,
  transform = (e) => e,
  getStatus,
  debounce = 1500,
  validateOnBlur = true,
  validator,
  ...textProps
}: Props) {
  const debouncedSyncToServer = useCallback(_.debounce(onSyncToServer, debounce), [onSyncToServer]);
  const [value, setValue] = useState<string>(transform(defaultValue || ""));
  const [isValid, setIsValid] = useState<boolean>(true);
  const textInputValidationOnBlurEnabled = useTextInputValidationOnBlurEnabled();

  useEffect(() => {
    return () => {
      debouncedSyncToServer.cancel();
    };
  }, [id]);

  if (textInputValidationOnBlurEnabled) {
    return (
      <TextField
        {...Object.assign(textProps, getStatus ? { status: getStatus(value) } : {})}
        id={id}
        value={value}
        onChange={(e) => {
          const value = transform(e.target.value);
          setValue(value);
          // we should save the value only when it is valid (improvement after we go live on this feature)
          debouncedSyncToServer(value);
        }}
        status={isValid ? (isEmpty(value) ? Status.Default : Status.Correct) : Status.Error}
        onBlur={(e) => {
          const currentValue = e.target.value;
          // run validation on blur if set
          if (validateOnBlur && validator) {
            const result = validator(currentValue);
            setIsValid(!!result);
          }
        }}
      />
    );
  }

  return (
    <TextField
      {...Object.assign(textProps, getStatus ? { status: getStatus(value) } : {})}
      id={id}
      value={value}
      onChange={(e) => {
        const value = transform(e.target.value);
        setValue(value);
        debouncedSyncToServer(value);
      }}
    />
  );
}

import { SelectField, Props as SelectFieldProps } from "@doorstead/components/fields/SelectField";
import { useState } from "react";
import { useTextInputValidationOnBlurEnabled } from "src/hooks/applicants/useTextInputValidationOnBlurEnabled";

export type Props = SelectFieldProps & {
  value: string;
  validateOnBlur?: boolean;
  validator?: (value: string) => boolean;
};

export function ApplicantSelectField({
  options = [],
  value = "",
  isError,
  validator,
  validateOnBlur = true,
  onChange,
}: Props) {
  const [isValid, setIsValid] = useState<boolean>(true);
  const textInputValidationOnBlurEnabled = useTextInputValidationOnBlurEnabled();

  return (
    <SelectField
      id="type"
      label="Type"
      value={value}
      isError={textInputValidationOnBlurEnabled ? !isValid : isError}
      options={options}
      onChange={onChange}
      onBlur={() => {
        // run validation on blur if set
        if (validateOnBlur && validator) {
          const result = validator(value);
          setIsValid(!!result);
        }
      }}
    />
  );
}

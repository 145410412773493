import { useState } from "react";
import "react-day-picker/dist/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUseApplicationFlowV2 } from "src/hooks/applicants/useUseApplicationFlowV2";
import { useSuccess } from "src/hooks/useSuccess";
import { ApplicantNodeStatus } from "../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../hooks/applicants/useFetchedApplicant";
import { useIsCoTenantsInviteV2Feature } from "../../../hooks/applicants/useIsCoTenantsInviteV2Feature";
import { useIsInvitedCoTenantsAndGuarantors } from "../../../hooks/applicants/useIsInvitedCoTenantsAndGuarantors";
import { useIsPrimaryApplicant } from "../../../hooks/applicants/useIsPrimaryApplicant";
import { useStartApplicant } from "../../../hooks/applicants/useStartApplicant";
import { useRenderOnce } from "../../../hooks/useRenderOnce";
import { Error } from "../../Error";
import { ApplicantInCompleteCard } from "./ApplicantInCompleteCard";
import { OnboardingSteps } from "./ApplicantInCompleteCard/ApplicantInCompleteCard";
import { ApplicantNewCard } from "./ApplicantNewCard";
import { ApplicantSubmittedCard } from "./ApplicantSubmittedCard";
import { PrimaryApplicantPreInCompleteCard } from "./PrimaryApplicantPreInCompleteCard/PrimaryApplicantPreInCompleteCard";

export function ApplicantOneSteps() {
  const navigate = useNavigate();
  const isCoTenantsInviteV2Feature = useIsCoTenantsInviteV2Feature();
  const useApplicationFlowV2 = useUseApplicationFlowV2();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const { isLoading, mutate: startApplication } = useStartApplicant();
  const [params] = useSearchParams();
  const isInvited = params.get("invited") === "true";
  const success = useSuccess();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const isInvitedCoTenantsAndGuarantors = useIsInvitedCoTenantsAndGuarantors();
  useRenderOnce(() => {
    // this should be obsolete, since chakra toast itself is a hook already
    if (isInvited) {
      success({ title: "Co-applicants invited" });
      //remove isInvited from the url
      navigate({ search: "" });
    }
  });
  const applicant = useFetchedApplicant();

  if (useApplicationFlowV2) {
    if ([ApplicantNodeStatus.NEW, ApplicantNodeStatus.INCOMPLETE].includes(applicant.status)) {
      if (isPrimaryApplicant && !isInvitedCoTenantsAndGuarantors) {
        return <PrimaryApplicantPreInCompleteCard />;
      }
      return <OnboardingSteps onBack={() => setIsLandingPage(true)} />;
    } else if (
      [
        ApplicantNodeStatus.SUBMITTED,
        ApplicantNodeStatus.LEASE_SIGNATURE_PENDING,
        ApplicantNodeStatus.LEASE_SIGNED,
      ].includes(applicant.status)
    ) {
      return <ApplicantSubmittedCard />;
    } else {
      return <Error title="We can not get the status for this applicant" />;
    }
  } else {
    if (applicant.status === ApplicantNodeStatus.NEW) {
      return (
        <ApplicantNewCard
          isLoading={isLoading}
          onContinue={async () => {
            await startApplication();
            setIsLandingPage(false);
          }}
        />
      );
    } else if (applicant.status === ApplicantNodeStatus.INCOMPLETE) {
      if (isCoTenantsInviteV2Feature && isPrimaryApplicant && !isInvitedCoTenantsAndGuarantors) {
        return <PrimaryApplicantPreInCompleteCard />;
      }
      return (
        <ApplicantInCompleteCard
          isLandingPage={isLandingPage}
          onContinue={() => setIsLandingPage(false)}
          onBack={() => setIsLandingPage(true)}
        />
      );
    } else if (
      [
        ApplicantNodeStatus.SUBMITTED,
        ApplicantNodeStatus.LEASE_SIGNATURE_PENDING,
        ApplicantNodeStatus.LEASE_SIGNED,
      ].includes(applicant.status)
    ) {
      return <ApplicantSubmittedCard />;
    } else {
      return <Error title="We can not get the status for this applicant" />;
    }
  }
}

import { Box, Checkbox, Flex, HStack, Link, Text, VStack } from "@chakra-ui/react";
import { ExclamationIcon, OutlineButton, PrimaryButton, moneyString } from "@doorstead/components";
import { Card } from "@doorstead/components/Card";
import { useEffect, useState } from "react";
import { CardTransactionFeeTooltip } from "src/components/CardTransactionFeeTooltip";
import { PaymentMethodSection } from "src/components/evergreens/EverGreenPaymentMethods";
import { useIsApplicantEditable } from "src/hooks/applicants/useIsApplicantEditable";
import { useIsPrimaryApplicant } from "src/hooks/applicants/useIsPrimaryApplicant";
import { useChargeBreakdown } from "src/hooks/useChargeBreakdown";
import { useDefaultPaymentMethodId } from "src/hooks/useDefaultPaymentMethodId";
import { useFeeName } from "src/hooks/useFeeName";
import { usePropertyState } from "src/hooks/usePropertyState";
import { useSelectedPaymentMethod } from "src/hooks/useSelectedPaymentMethod";
import { getCents } from "src/utils/getCents";
import { getPaymentMethodLabel } from "src/utils/getPaymentMethodLabel";
import { useApplicantAnalytics } from "../../../../../hooks/applicants/useApplicantAnalytics";
import {
  ApplicantNodeStatus,
  ApplicantOnboardingStage,
} from "../../../../../hooks/applicants/useFetchApplicationMe/Applicant";
import { useFetchedApplicant } from "../../../../../hooks/applicants/useFetchedApplicant";
import { useIsZtpo } from "../../../../../hooks/applicants/useIsZtpo";
import { usePayApplicantFee } from "../../../../../hooks/applicants/usePayApplicantFee";
import { useUpdateApplicantOnboardingStage } from "../../../../../hooks/applicants/useUpdateApplicantOnboardingStage";
import { padding } from "../../config";
import { getErrorTexts, useFieldErrors } from "./useFieldErrors";

export function PayCard() {
  const applicantAnalytics = useApplicantAnalytics();
  const applicant = useFetchedApplicant();
  const propertyState = usePropertyState();
  const isPrimaryApplicant = useIsPrimaryApplicant();
  const isZtpo = useIsZtpo();
  const isZtpoPrice = isZtpo;
  const amount = isZtpoPrice ? 5 : 45;
  const hasAgreedApplicationTerms = applicant.hasAgreed ?? false;
  const hasAgreedRentalPolicy = applicant.hasAgreed ?? false;
  const hasLeaseStartDate = isPrimaryApplicant ? applicant.applicationRequest.negotiatedMoveInDate !== null : true;
  const [isApplicationTermsAgree, setIsApplicationTermsAgree] = useState(hasAgreedApplicationTerms);
  const [isRentalPolicyAgreed, setIsRentalPolicyAgreed] = useState(hasAgreedRentalPolicy);
  const { mutate: updateOnboarding, isLoading: isOnboardingLoading } = useUpdateApplicantOnboardingStage();
  const { mutate: payPlatformFee, isLoading: payPlatformFeeLoading } = usePayApplicantFee();
  const { hasError, errors } = useFieldErrors();
  const isApplicationPaidSubmitted = !useIsApplicantEditable();
  const isPayButtonShow = applicant.status === ApplicantNodeStatus.INCOMPLETE;
  const defaultPaymentMethodId = useDefaultPaymentMethodId();
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(defaultPaymentMethodId);
  const selectedPaymentMethod = useSelectedPaymentMethod(paymentMethodId);
  const { chargeAmount, cardTransactionFee, isFetching } = useChargeBreakdown(amount, selectedPaymentMethod?.type_);
  const isSubmitDisabled =
    !isApplicationTermsAgree || !isRentalPolicyAgreed || !paymentMethodId || !hasLeaseStartDate || isFetching;
  const feeName = useFeeName();

  useEffect(() => {
    setIsApplicationTermsAgree(hasAgreedApplicationTerms);
  }, [hasAgreedApplicationTerms]);

  return (
    <Card padding={padding}>
      <VStack spacing={10} w="100%">
        <Box w="100%" color="gray.900" fontWeight="semibold" fontSize="3xl">
          Submit and pay
        </Box>

        <PaymentMethodSection setPaymentMethodId={setPaymentMethodId} />

        <VStack width="100%" spacing="0">
          <Flex width="100%" direction="row" justifyContent="space-between">
            <Box fontFamily="Inter" fontWeight="500" color="gray.800">
              Application fee
            </Box>
            {isZtpoPrice && (
              <Box fontFamily="Inter" fontWeight="400" color="gray.400" textDecoration="line-through">
                $45
              </Box>
            )}
            {!isZtpoPrice && (
              <Box fontFamily="Inter" fontWeight="700" color="gray.800">
                $45
              </Box>
            )}
          </Flex>
          {isZtpoPrice && (
            <Flex width="100%" direction="row" justifyContent="space-between" mb="2">
              <Box fontFamily="Inter" fontWeight="700" color="green.500">
                Limited-time discount
              </Box>
              <Box fontFamily="Inter" fontWeight="700" color="gray.800">
                $5
              </Box>
            </Flex>
          )}

          {cardTransactionFee !== 0 && (
            <Flex width="100%" direction="row" justifyContent="space-between" pt="2">
              <Box w="100%">
                <Flex alignItems="center">
                  <Text fontFamily="Inter" fontWeight="500" color="gray.800" mr="2">
                    Card transaction fee
                  </Text>
                  <CardTransactionFeeTooltip>
                    <Box>
                      <ExclamationIcon />
                    </Box>
                  </CardTransactionFeeTooltip>
                </Flex>
              </Box>
              <Box fontFamily="Inter" fontWeight="600" color="gray.800">
                {`$${cardTransactionFee}`}
              </Box>
            </Flex>
          )}
          <Flex width="100%" direction="row" justifyContent="space-between" pt="4">
            <Box fontFamily="Inter" fontWeight="700" fontSize="18px" color="gray.800">
              Total
            </Box>
            <Box fontFamily="Inter" fontWeight="700" fontSize="18px" color="gray.800">
              {`$${chargeAmount}`}
            </Box>
          </Flex>
        </VStack>

        {/* {isZtpoPrice ? (
          <LeftRightBox
            left={
              <VStack alignItems="flex-start">
                <Box>{feeName}</Box>
                <Box>per applicant</Box>
                <LimitedTimeDiscountBox />
              </VStack>
            }
            right={<ZTPoPriceBox />}
          />
        ) : (
          <VStack spacing={3} w="100%">
            <LeftRightBox left={feeName} right={<MoneyText>{moneyString(amount)}</MoneyText>} />
          </VStack>
        )} */}

        {/* <TotalChargeSection cardTransactionFee={cardTransactionFee} chargeAmount={chargeAmount} /> */}

        <VStack spacing={3} w="100%">
          <Flex
            as="button"
            w="100%"
            padding={5}
            bgColor="gray.50"
            alignItems="flex-start"
            cursor={isApplicationPaidSubmitted ? "not-allowed" : "pointer"}
            onClick={() => {
              if (!isApplicationPaidSubmitted) {
                setIsRentalPolicyAgreed((isRentalPolicyAgreed) => {
                  applicantAnalytics?.track("TP_PAY_CARD_AGREE_POLICY_CLICKED", { isRentalPolicyAgreed });
                  return !isRentalPolicyAgreed;
                });
              }
            }}
          >
            <Checkbox isDisabled={isApplicationPaidSubmitted} isChecked={isRentalPolicyAgreed} pointerEvents="none" />
            <Box ml={5} color="gray.500" textAlign="left">
              I acknowledge receipt of{" "}
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                }}
                target="_blank"
                href="https://www.doorstead.com/statement-of-rental-policy"
              >
                Doorstead Statement of Rental Policy
              </Link>
              {propertyState === "TX" && (
                <Box>
                  and{" "}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    href="https://assets.website-files.com/62a0ed35b9f7fb5bfa645b1c/66164dbd9cb97a5acb8affd2_IABS-DoorsteadInc.pdf"
                  >
                    Brokerage Disclosure
                  </Link>
                </Box>
              )}
            </Box>
          </Flex>

          <Flex
            as="button"
            w="100%"
            padding={5}
            bgColor="gray.50"
            alignItems="flex-start"
            cursor={isApplicationPaidSubmitted ? "not-allowed" : "pointer"}
            onClick={() => {
              if (!isApplicationPaidSubmitted) {
                setIsApplicationTermsAgree((isApplicationTermsAgree) => {
                  applicantAnalytics?.track("TP_PAY_CARD_AGREE_CLICKED", { isApplicationTermsAgree });
                  return !isApplicationTermsAgree;
                });
              }
            }}
          >
            <Checkbox
              isDisabled={isApplicationPaidSubmitted}
              isChecked={isApplicationTermsAgree}
              pointerEvents="none"
            />
            <Box ml={5} color="gray.500" textAlign="left">
              I agree that information submitted in application is accurate and complete. I understand that the
              application for the property will be reviewed by Doorstead once all co-applicants have submitted their
              individual applications and paid their non-refundable {feeName.toLowerCase()}s.
              <br />
              <br />I authorize Doorstead Inc. to obtain my consumer credit report for residential lease qualification.
              Upon request, Doorstead will provide the Consumer Reporting Agency’s details. I acknowledge that credit
              inquiries may affect my credit score.
              {propertyState === "MA" && (
                <>
                  <br />
                  <br />I acknowledge that Doorstead acts through a broker in the State of Massachusetts, and authorize
                  a non-refundable placement fee in addition to the {feeName.toLowerCase()}. Notice of the broker fee
                  can be downloaded{" "}
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    href="https://cdn.doorstead.com/pdfs/Placement+Broker+Fee+Statement+(MA).pdf"
                  >
                    here
                  </Link>
                  .
                </>
              )}
            </Box>
          </Flex>
        </VStack>
        <HStack w="100%" spacing={5}>
          <OutlineButton
            isLoading={isOnboardingLoading}
            onClick={() => {
              applicantAnalytics?.track("TP_PAY_CARD_BACK_CLICKED");
              updateOnboarding(ApplicantOnboardingStage.EMPLOYMENT_VERIFICATIONS_SECTION);
            }}
          >
            Back
          </OutlineButton>
          {isPayButtonShow && (
            <PrimaryButton
              disabled={isSubmitDisabled}
              isLoading={payPlatformFeeLoading}
              onClick={async () => {
                if (hasError) {
                  window.alert(getErrorTexts(errors));
                } else if (
                  paymentMethodId !== null &&
                  selectedPaymentMethod !== null &&
                  window.confirm(
                    `Are you sure to pay ${moneyString(chargeAmount)} with ${getPaymentMethodLabel({
                      type: selectedPaymentMethod.type_,
                      bankAccountName: selectedPaymentMethod.bankAccountName,
                      bankAccountMask: selectedPaymentMethod.bankAccountMask,
                      cardBrand: selectedPaymentMethod.cardBrand,
                      cardLastFourDigits: selectedPaymentMethod.cardLastFourDigits,
                    })}?`
                  )
                ) {
                  const variables = {
                    paymentMethodId,
                    amountInCents: getCents(chargeAmount),
                  };
                  applicantAnalytics?.track("TP_SUBMIT_AND_PAY_CLICKED", variables);
                  await payPlatformFee(variables);
                }
              }}
            >
              Submit and pay
            </PrimaryButton>
          )}
        </HStack>
      </VStack>
    </Card>
  );
}

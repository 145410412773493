import { Box, Checkbox, Collapse, Divider, Flex, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useProofOfIncomeCheckboxEnabled } from "src/hooks/applicants/useProofOfIncomeCheckboxEnabled";

type Row = {
  id: string;
  title: string;
  component: React.FC;
};

type Props = {
  title: string;
  rows: Row[];
  defaultChecked?: boolean;
  checkboxAction?: (isChecked: boolean) => void;
};

export function EditRow({ title, rows, defaultChecked = false, checkboxAction }: Props) {
  const proofOfIncomeCheckboxEnabled = useProofOfIncomeCheckboxEnabled();

  const [isChecked, setIsChecked] = useState(defaultChecked);
  const handleCheckboxOnClick = () => {
    setIsChecked(!isChecked);
    if (checkboxAction) {
      checkboxAction(!isChecked);
    }
  };

  if (proofOfIncomeCheckboxEnabled) {
    return (
      <VStack
        bgColor="gray.50"
        color="gray.800"
        lineHeight="24px"
        padding={5}
        w="100%"
        alignItems="flex-start"
        spacing={5}
        borderRadius="6px"
      >
        <Flex w="100%" direction="row">
          <Checkbox isChecked={isChecked} onChange={handleCheckboxOnClick} />
          <Box pl="2">{title}</Box>
        </Flex>
        <Collapse in={isChecked}>
          <VStack spacing={5} w="100%">
            <Divider />
            {rows.map(({ id, title, component }) => (
              <Flex key={id} w="100%" justifyContent="space-between" alignItems="center">
                <Box>{title}</Box>
                {component({})}
              </Flex>
            ))}
          </VStack>
        </Collapse>
      </VStack>
    );
  }

  return (
    <VStack
      bgColor="gray.50"
      color="gray.800"
      lineHeight="24px"
      padding={5}
      w="100%"
      alignItems="flex-start"
      spacing={5}
      borderRadius="6px"
    >
      <Box>{title}</Box>
      <Divider />
      {rows.map(({ id, title, component }) => (
        <Flex key={id} w="100%" justifyContent="space-between" alignItems="center">
          <Box>{title}</Box>
          {component({})}
        </Flex>
      ))}
    </VStack>
  );
}
